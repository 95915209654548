import useGrowersTable from './useGrowersTable';
import GenericTable from '../../design/GenericTable/GenericTable';
import ActionButton, {
    ActionMenuItem,
} from '../../design/Buttons/ActionButton';
import PATHS from '../../router/paths';
import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useManagePermission from '../../hooks/useManagePermission';
export default function GrowersTable() {
    const navigate = useNavigate();
    const { totalCount, data, tableConfig } = useGrowersTable();
    const { checkPermission } = useManagePermission();
    const actionButtonItems: ActionMenuItem[] = [
        {
            label: 'Add a Grower',
            onClick: () => {
                navigate(`${PATHS.create}`, { state: 'GROWER' });
            },
            hidden: !checkPermission('growers', 'modify'),
        },
    ];
    return (
        <div>
            <Grid
                container
                justifyContent={'flex-end'}
                width={'100%'}
                sx={{
                    padding: 2,
                }}>
                <ActionButton label={'Actions'} items={actionButtonItems} />
            </Grid>
            <GenericTable
                data={data}
                tableConfig={tableConfig}
                totalCount={totalCount}
            />
        </div>
    );
}
