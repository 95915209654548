import React, { useEffect, useState } from 'react';
import { useGetAllContactsQuery } from '../../../api/contacts/contactsAPI';
import { Box, Divider, Grid, MenuItem, Select } from '@mui/material';
import AutocompleteField, {
    AutocompleteOption,
} from '../../../design/Fields/AutocompleteField';
import FieldLabelWithTooltip from '../../../design/Fields/FieldLabelWithTooltip';
import AppTypography from '../../../design/AppTypography';
import Loader from '../../../design/BaseLoader';
import { Option } from '../../../pages/Settings/SharingSettings/ShareDataSelectField';
import { ContactLandPartnerView } from '../../../pages/AllProfiles/AllProfilesTable';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import { SelectChangeEvent } from '@mui/material/Select';

interface OwnProps {
    input: FieldInputProps<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        HTMLElement
    >;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: FieldMetaState<any>;
}

type Permissions = 'VIEW';
interface UserAccess {
    contactId: string;
    accessType: Permissions;
}

export default function SharedContactArrayField({ input, meta }: OwnProps) {
    const { value, onChange } = input;

    const [userAccess, setUserAccess] = useState<UserAccess[]>(value || []);
    const [contactsId, setContactsId] = useState<AutocompleteOption[]>(
        value || [],
    );

    function handleContacts(value: AutocompleteOption[]) {
        setUserAccess(
            value.map((contact) => ({
                contactId: contact.id,
                accessType: 'VIEW',
            })),
        );
        setContactsId(value);
    }

    function handleUserAccess(accessType: Permissions, contactId: string) {
        setUserAccess((prev) => [
            ...prev.filter((ua) => ua.contactId !== contactId),
            {
                contactId,
                accessType,
            },
        ]);
    }

    useEffect(() => {
        onChange(userAccess);
    }, [userAccess]);

    const { data, isLoading } = useGetAllContactsQuery({
        page: 0,
        size: 2000,
    });

    if (isLoading) {
        return (
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            rowGap={2}>
            <Box width={'100%'}>
                <FieldLabelWithTooltip
                    isRequired={true}
                    label={'Whom do you want to share with?'}
                />
                <AutocompleteField
                    onBlur={() => input?.onBlur()}
                    value={contactsId}
                    errorText={meta.touched && meta.error}
                    getOptionDisabled={(option: AutocompleteOption) => {
                        return !option?.email;
                    }}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={handleContacts}
                    options={convertContactToOption(data?.data || [])}
                    name="contacts"
                />
            </Box>
            {contactsId.map((contact: AutocompleteOption) => {
                return (
                    <Box
                        key={contact.id}
                        width={'100%'}
                        display={'flex'}
                        columnGap={2}>
                        <Box width={'60%'}>
                            <AppTypography
                                flexWrap={true}
                                component={'span'}
                                variant={'font14'}
                                text={contact.name}
                                fontWeight={'bold'}
                            />
                        </Box>
                        <Box width={'40%'}>
                            <Select
                                fullWidth
                                size={'small'}
                                sx={{ height: '30px' }}
                                value={
                                    userAccess.find(
                                        (ua) => ua.contactId === contact.id,
                                    )?.accessType || 'VIEW'
                                }
                                onChange={(e: SelectChangeEvent<Permissions>) =>
                                    handleUserAccess(
                                        e.target.value as Permissions,
                                        contact.id,
                                    )
                                }>
                                <MenuItem value={'VIEW'}>Viewer</MenuItem>
                                <Divider
                                    orientation="horizontal"
                                    variant={'middle'}
                                    flexItem
                                />
                            </Select>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
}

function convertContactToOption(contacts: ContactLandPartnerView[]): Option[] {
    return contacts.map((contact) => ({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName} - ${contact?.primaryEmail || 'Email address not available'}`,
        email: contact.primaryEmail,
    }));
}
