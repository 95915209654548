import {
    BaseQueryApi,
    createApi,
    FetchArgs,
} from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from '../constant/localStorageKeys';
import {
    ACCOUNT,
    ACTIVITY,
    AVATAR,
    BUG_REPORTS,
    CALENDAR,
    CONTACT_NOTES,
    CONTACTS,
    CUSTOMIZE_CONFIG,
    DASHBOARD,
    DOCUMENTS,
    EMAIL,
    GROWERS,
    LAND_PARTNERS,
    LANDOWNERS,
    LEASE_NOTES,
    LEASES,
    NOTIFICATIONS,
    ORGANIZATIONS,
    PAYMENTS,
    PERMISSIONS,
    ROLES,
    SEARCH,
    TASKS,
    USERS,
} from './tags';

import PATHS from '../router/paths';
import jwtDecode from 'jwt-decode';

interface RefreshTokenResponse {
    accessToken: string;
    refreshToken: string;
}

interface DecodedToken {
    exp?: number;
}

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem(TOKEN_KEY) || null;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
const excludedPaths = [
    `/${PATHS.auth}/${PATHS.logIn}`,
    `/${PATHS.auth}/${PATHS.userActivation}`,
    `/${PATHS.auth}/${PATHS.expiredLink}`,
    `/${PATHS.auth}/${PATHS.forgotPassword}`,
    `/${PATHS.auth}/${PATHS.resetPassword}`,
    `/${PATHS.auth}/${PATHS.requestInvite}`,
    `/${PATHS.sharedData.root}/${PATHS.sharedData.metrics}`,
    `/${PATHS.sharedData.root}/${PATHS.sharedData.all}`,
    `/${PATHS.sharedData.root}/${PATHS.sharedData.preview}`,
    `/${PATHS.contact}/${PATHS.verifyEmail}`,
];

function checkingIfTokenExpired(token: string) {
    const decoded = jwtDecode<DecodedToken>(token);
    const dateNow = new Date();

    return !!(decoded?.exp && decoded.exp * 1000 < dateNow.getTime());
}

export const baseQueryWithReauth = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: NonNullable<unknown>,
) => {
    let result = await baseQueryWithAuth(args, api, extraOptions);

    if (
        result.error &&
        result.error.status === 401 &&
        !excludedPaths.includes(window.location.pathname)
    ) {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (!refreshToken) {
            window.location.href = `/${PATHS.auth}/${PATHS.logIn}`;
        }
        if (
            refreshToken &&
            checkingIfTokenExpired(refreshToken) &&
            window.location.pathname !== `/${PATHS.auth}/${PATHS.logIn}`
        ) {
            window.location.href = `/${PATHS.auth}/${PATHS.logIn}`;
        }

        if (
            refreshToken &&
            checkingIfTokenExpired(refreshToken) &&
            window.location.pathname === `/${PATHS.auth}/${PATHS.logIn}`
        ) {
            localStorage.clear();
            result = await baseQueryWithAuth(args, api, extraOptions);
            return result;
        }

        if (refreshToken) {
            const refreshResult = await baseQueryWithAuth(
                {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        '/auth/refresh-token',
                    method: 'POST',
                    body: { refreshToken },
                },
                api,
                {
                    ...extraOptions,
                },
            );

            if (refreshResult.data) {
                const refreshTokenResult =
                    refreshResult.data as RefreshTokenResponse;

                localStorage.setItem(
                    REFRESH_TOKEN_KEY,
                    refreshTokenResult.refreshToken,
                );
                localStorage.setItem(TOKEN_KEY, refreshTokenResult.accessToken);

                result = await baseQueryWithAuth(args, api, extraOptions);

                if (!result.data) {
                    console.error('Refresh token error');
                    localStorage.clear();
                    window.location.href = `/${PATHS.auth}/${PATHS.logIn}`;
                }
            }
            if (!refreshResult.data) {
                console.error('Refresh token error');
                window.location.href = `/${PATHS.auth}/${PATHS.logIn}`;
            }
        }
    }

    return result;
};
export const OAKENApi = createApi({
    reducerPath: 'OAKENApi',
    baseQuery: baseQueryWithReauth,
    refetchOnMountOrArgChange: 30,
    tagTypes: [
        LANDOWNERS.LANDOWNERS,
        LAND_PARTNERS.LAND_PARTNERS,
        LEASES.LEASES,
        ACCOUNT.ACCOUNT,
        ACCOUNT.ACCOUNT_LAYOUTS,
        USERS.USERS,
        ORGANIZATIONS.ORGANIZATIONS,
        ORGANIZATIONS.ORGANIZATION_CONFIG,
        ROLES.ROLES,
        BUG_REPORTS.BUG_REPORTS,
        CONTACTS.CONTACTS,
        CONTACT_NOTES.CONTACT_NOTES,
        LEASE_NOTES.LEASE_NOTES,
        DOCUMENTS.DOCUMENTS,
        DOCUMENTS.GROUPS,
        NOTIFICATIONS.NOTIFICATIONS,
        NOTIFICATIONS.NOTIFICATIONS_INFO,
        PAYMENTS.PAYMENTS,
        CUSTOMIZE_CONFIG.CUSTOMIZE_CONFIG,
        SEARCH.SEARCH,
        DASHBOARD.DASHBOARD,
        TASKS.TASKS,
        CALENDAR.CALENDAR,
        EMAIL.EMAIL,
        PERMISSIONS.PERMISSIONS,
        ACTIVITY.ACTIVITY,
        AVATAR.AVATAR,
        DOCUMENTS.DOCUMENT,
        GROWERS.GROWERS,
    ],
    endpoints: () => ({}),
});
