import PATHS from '../../router/paths';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard_icon.svg';
import { ReactComponent as LandPartnersIcon } from '../../assets/icons/landpartners_icon.svg';
import { ReactComponent as LandPortfolioIcon } from '../../assets/icons/land_portfolio_icon.svg';
import { ReactComponent as PaymentsIcon } from '../../assets/icons/payments_icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings_icon.svg';
import { ReactComponent as AwardIcon } from '../../assets/icons/award_icon.svg';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import React, { ReactNode, useEffect } from 'react';
import useManagePermission from '../../hooks/useManagePermission';
import { useLazyGetCurrentSubscriptionQuery } from '../../api/subscriptions/subscriptionsAPI';

export interface NavigationItemProps {
    label: string;
    path: string;
    icon: ReactNode;
    subItems?: SubNavigationItemProps[];
    isHidden?: boolean;
}

export interface SubNavigationItemProps {
    label: string;
    path: string;
    roles?: string[];
    isHidden?: boolean;
}

export function useNavigationConfig(): { config: NavigationItemProps[] } {
    const { checkPermission } = useManagePermission();

    const [getItems, { data: subscriptionInfo }] =
        useLazyGetCurrentSubscriptionQuery({});

    useEffect(() => {
        getItems();
    }, []);

    const isFieldManagementHidden =
        !checkPermission('fieldManagement', 'read') ||
        !subscriptionInfo?.features?.fieldManagement;

    const isLeaseManagementHidden = !checkPermission('leases', 'read');

    const config = [
        {
            label: 'Dashboard',
            path: PATHS.dashboard,
            icon: <DashboardIcon />,
        },
        {
            label: 'Profiles',
            path: PATHS.landpartners,
            icon: <LandPartnersIcon />,
            isHidden:
                !checkPermission('landPartners', 'read') &&
                !checkPermission('contacts', 'read'),

            subItems: [
                {
                    label: 'Land Partners',
                    path: `/${PATHS.landpartners}/${PATHS.profiles}`,
                    isHidden: !checkPermission('landPartners', 'read'),
                },
                {
                    label: 'Growers',
                    path: `/${PATHS.landpartners}/${PATHS.growers}`,
                    isHidden: !checkPermission('growers', 'read'),
                },
                {
                    label: 'All Profiles',
                    path: `/${PATHS.landpartners}/${PATHS.allProfiles}`,
                    isHidden: !checkPermission('contacts', 'read'),
                },
            ],
        },
        {
            label: 'Land Portfolio',
            path: PATHS.landPortfolio,
            icon: <LandPortfolioIcon />,
            isHidden: isLeaseManagementHidden && isFieldManagementHidden,
            subItems: [
                {
                    label: 'Leases',
                    path: `/${PATHS.landPortfolio}/${PATHS.manage}`,
                    isHidden: isLeaseManagementHidden,
                },
                {
                    label: 'Fields',
                    path: `/${PATHS.landPortfolio}/${PATHS.fields}`,
                    isHidden: isFieldManagementHidden,
                },
            ],
        },
        {
            label: 'Services',
            path: PATHS.services,
            icon: <AwardIcon style={{ width: 18, height: 18 }} />,
            isHidden: !checkPermission('services', 'read'),
            subItems: [
                {
                    label: 'Disaster Planning',
                    path: `/${PATHS.services}/${PATHS.disasterPlanning}`,
                },
                {
                    label: 'All Services',
                    path: `/${PATHS.services}/${PATHS.allServices}`,
                },
            ],
        },
        {
            label: 'Payments',
            path: PATHS.payments,
            icon: <PaymentsIcon />,
            isHidden: !checkPermission('payments', 'read'),
            subItems: [
                {
                    label: 'Overview',
                    path: `/${PATHS.payments}/${PATHS.overview}`,
                },
            ],
        },
        {
            label: 'Other Services',
            path: PATHS.otherServices,
            icon: <LightbulbOutlinedIcon />,
            isHidden: !checkPermission('insights', 'read'),
        },
    ];
    return {
        config,
    };
}

export function useNavigationSettingsConfig({
    role,
}: {
    role: string;
}): NavigationItemProps {
    const { checkPermission } = useManagePermission();
    const allSubItems = [
        {
            label: 'Org + Users',
            path: `/${PATHS.settings}/${PATHS.orgAndUsers}`,
            roles: ['ADMIN'],
        },
        {
            label: 'Notifications',
            path: `/${PATHS.settings}/${PATHS.notificationsSettings}`,
            roles: ['ADMIN'],
        },
        {
            label: 'Views',
            path: `/${PATHS.settings}/${PATHS.viewsSettings}`,
            roles: ['ADMIN'],
        },
    ];
    const subItems = allSubItems.filter((item) => item.roles.includes(role));
    const settingsPath =
        role === 'ADMIN' ? PATHS.settings : PATHS.generalManagerSettings.root;
    return {
        label: 'Settings',
        path: settingsPath,
        icon: <SettingsIcon />,
        isHidden:
            !['ADMIN'].includes(role) && !checkPermission('settings', 'read'),
        subItems,
    };
}
