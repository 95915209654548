import React from 'react';
import OtherServicesCard, { CardOtherServices } from './OtherServicesCard';
import { Box, Grid } from '@mui/material';
import AppTypography from '../../design/AppTypography';
import sowerLogo from '../../assets/images/sowerLogo.png';
import { Form } from 'react-final-form';

import FormPage from '../../reusable/FormUI/FormPage';
import { FormConfig } from '../../design/Forms/interfaces';

export default function OtherServicesWrappedCardList() {
    return (
        <Grid container spacing={3} flex={1}>
            {Config().map((item, index) => (
                <Grid item key={index} xs={6}>
                    <OtherServicesCard key={index} {...item} />
                </Grid>
            ))}
        </Grid>
    );
}

function Config(): CardOtherServices[] {
    return [
        {
            header: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={sowerLogo} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Outright Purchase Loans'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'Farmland purchase loans tailored to your needs, with competitive rates and flexible payment options. These are long-term loans to finance or refinance farmland, pastureland, and other ground used for agricultural purposes. You can also use these loans for land improvements, such as fencing, grain bins, and facilities.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
        {
            header: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={sowerLogo} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Liquidity with 721 Exchange Facility'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'You can take advantage of a section 721 exchange to defer capital gains taxes, which can otherwise significantly reduce the profits of a real estate property sale. Diversify your portfolio and obtain access to professional management and liquidity.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
        {
            header: (
                <AppTypography
                    flexWrap={true}
                    variant={'font20'}
                    fontWeight={'medium'}
                    text={'Access Capitol with Lease + Buyback'}
                />
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                    <Form onSubmit={() => {}} initialValues={{}}>
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <FormPage formConfig={formConfig} />
                            </form>
                        )}
                    </Form>
                </Box>
            ),
            button: {
                text: 'Submit',
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
        {
            header: (
                <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                    <img width={240} src={sowerLogo} alt={'sower logo'} />
                </Box>
            ),
            body: (
                <Box display={'flex'} flexDirection={'column'} rowGap={2}>
                    <AppTypography
                        flexWrap={true}
                        variant={'font20'}
                        fontWeight={'medium'}
                        text={'Access Short Term Capital'}
                    />
                    <AppTypography
                        flexWrap={true}
                        variant={'font14'}
                        fontWeight={'regular'}
                        text={
                            'A line of credit based on the appreciation of your farmland can meet your short-term capital needs. Replenish your credit line as you pay down the principal amount, or consider using the ag equity line to replace annual operating lines of credit.'
                        }
                    />
                </Box>
            ),
            button: {
                text: 'Get Started',
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
            },
        },
    ];
}
export const formConfig: FormConfig[] = [
    {
        formField: {
            scheme: 'half',
            name: `firstName`,
            type: 'input',
            isRequired: true,
            label: 'First Name',
        },
        renderProps: {
            placeholder: '[auto fill with log in profile info]',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `lastName`,
            type: 'input',
            isRequired: true,
            label: 'Last Name',
        },
        renderProps: {
            placeholder: '[auto fill with log in profile info]',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `email`,
            type: 'input',
            isRequired: true,
            label: 'Email',
        },
        renderProps: {
            placeholder: '[auto fill with log in profile info]',
            isDisabled: false,
            size: 'small',
        },
    },
    {
        formField: {
            scheme: 'half',
            name: `phone`,
            type: 'input',
            isRequired: true,
            label: 'Phone',
        },
        renderProps: {
            placeholder: '[auto fill with log in profile info]',
            isDisabled: false,
            size: 'small',
        },
    },
];
