import { DocumentTable } from './useDocumentsTable';
import AppButton from '../../../design/Buttons/AppButton';
import { useState } from 'react';
import SharingDocumentModal from './SharingDocumentModal';

interface OwnProps {
    data: DocumentTable;
    isHidden?: boolean;
}

export default function ShareButton({ data, isHidden }: OwnProps) {
    const [isShowing, setIsShowing] = useState(false);

    function handleClick() {
        setIsShowing(true);
    }
    function handleClose() {
        setIsShowing(false);
    }

    if (isHidden) return null;

    return (
        <>
            <AppButton
                onClick={handleClick}
                title={'Share'}
                variant={'outlined'}
            />
            {isShowing && (
                <SharingDocumentModal
                    id={data?.id}
                    fileName={data?.fileName}
                    isShowing={isShowing}
                    handleClose={handleClose}
                />
            )}
        </>
    );
}
