import { ReactNode } from 'react';
import { Box, Button } from '@mui/material';

export interface CardOtherServices {
    header: ReactNode;
    body: ReactNode;
    button?: {
        text: string;
        onClick: () => void;
    };
}

export default function OtherServicesCard({
    header,
    body,
    button,
}: CardOtherServices) {
    return (
        <Box
            flexDirection={'column'}
            display={'flex'}
            rowGap={2.5}
            width={'100%'}
            p={2}
            borderRadius={'16px'}
            bgcolor={'#ffffff'}>
            <Box>{header}</Box>
            <Box>{body}</Box>

            {button && (
                <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}>
                    <Button variant={'contained'} onClick={button.onClick}>
                        {button.text}
                    </Button>
                </Box>
            )}
        </Box>
    );
}
