import { Navigate, Outlet, useLocation } from 'react-router-dom';
import theme from '../../styles/theme';
import PATHS from '../../router/paths';
import { Box } from '@mui/material';
export default function Services() {
    const { pathname } = useLocation();
    if (pathname === `/${PATHS.services}`)
        return <Navigate to={`/${PATHS.services}/${PATHS.disasterPlanning}`} />;
    return (
        <Box p={theme.spacing(1, 2)}>
            <Outlet />
        </Box>
    );
}
