import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import PATHS from './paths';

import LeaseWizard from '../features/CreateLeaseForm/LeaseWizard';
import UserPersonalInfo from '../features/UserProfile/UserPersonalInfo';
import PageWrapper from '../layouts/PageWrapper/PageWrapper';
import Communications from '../pages/Communications/Communications';
import Dashboard from '../pages/Dashboard/Dashboard';
import LandPortfolio from '../pages/LandPortfolio/LandPortfolio';
import LeaseDetailPage from '../pages/LandPortfolio/LeaseDetailPage';
import ManageLeasesPage from '../pages/LandPortfolio/ManageLeasesPage';
import Integrations from '../pages/Settings/Integrations/Integrations';
import NotificationsAndViews from '../pages/Settings/NotificationsAndViews';
import Organizations from '../pages/Settings/Organizations/Organizations';
import OrganizationsAndUsers from '../pages/Settings/OrganizationsAndUsers';
import Roles from '../pages/Settings/Roles/Roles';
import Settings from '../pages/Settings/Settings';
import Users from '../pages/Settings/Users/Users';
import ForgotPasswordForm from '../pages/SignIn/Forms/ForgotPasswordForm';
import LogInForm from '../pages/SignIn/Forms/LogInForm';
import RequestInviteForm from '../pages/SignIn/Forms/RequestInviteForm';
import ResetPasswordForm from '../pages/SignIn/Forms/ResetPasswordForm';
import SignIn from '../pages/SignIn/SignIn';
import ActivateAccountForm from '../pages/SignIn/Forms/ActivateAccountForm';
import StubDummyComponent from '../reusable/StubDummyComponent';
import LandPartners from '../pages/LandPartners/LandPartners';
import LandPartnerProfilesPage from '../pages/LandPartners/LandPartnerProfilesPage';
import LandPartnersDetailPage from '../pages/LandPartners/LandPartnersDetailPage';
import NotificationsList from '../features/Notifications/NotificationsList';
import NotificationsSettings from '../pages/Settings/Notifications/NotificationsSettings';
import AllProfiles from '../pages/AllProfiles/AllProfiles';
import AllProfilesDetailsPage from '../pages/AllProfiles/AllProfilesDetailsPage';
import LandPartnerWizard from '../features/CreateLandPartner/LandPartnerWizard';
import OrganizationWizard from '../pages/Settings/Organizations/AddOrganization/OrganizationWizard';
import AllTasks from '../pages/Tasks/AllTasks';
import PaymentOverview from '../features/PaymentsOverview/PaymentOverview';
import Payments from '../pages/Payments/Payments';
import ExpiredLinkScreen from '../pages/SignIn/ExpiredLinkScreen';
import ContactWizard from '../features/CreateContact/ContactWizzard';
import GeneralManagerSettings from '../pages/Settings/GeneralManagerSettings';
import GeneralManagerSettingsWrapper from '../pages/Settings/GeneralManagerSettingsWrapper';
import ManageLandPartnerSharing from '../pages/Settings/SharingSettings/ManageLandPartnerSharing';
import ArchiveObjects from '../pages/Settings/ArchiveObjects/ArchiveObjects';
import SharedDataPreviewPage from '../pages/SharedData/Preview/SharedDataPreviewPage';
import SharedDataMetricsPage from '../pages/SharedData/TemporaryAccess/SharedDataMetricsPage';
import SharedDataAllPage from '../pages/SharedData/TemporaryAccess/SharedDataAllPage';
import VerifyEmailScreen from '../pages/SignIn/VerifyEmailScreen';
import FarmProfile from '../features/FarmProfile/FarmProfile';
import Fields from '../pages/Fields/Fields';
import FieldsDetails from '../features/ViewFieldsDetails/FieldsDetails';
import Growers from '../pages/Growers/Growers';
import DisasterPlanning from '../features/Succession/DisasterPlanning';
import Services from '../pages/Services/Services';
import DisasterPlanningStepper from '../pages/DisasterPlanning/DisasterPlanningStepper';
import OtherServices from '../pages/OtherServices/OtherServices';
import RedirectPageUploadDocument from '../features/ViewLandownersDetails/LandPartnerDocuments/RedirectPageUploadDocument';

export const RouterConfig = createBrowserRouter([
    {
        path: `${PATHS.auth}/*`,
        Component: SignIn,
        children: [
            { index: true, path: `${PATHS.logIn}`, Component: LogInForm },
            { path: `${PATHS.requestInvite}`, Component: RequestInviteForm },
            { path: `${PATHS.resetPassword}`, Component: ResetPasswordForm },
            { path: `${PATHS.forgotPassword}`, Component: ForgotPasswordForm },
            { path: `${PATHS.userActivation}`, Component: ActivateAccountForm },
        ],
    },
    {
        path: `shared/documents/upload`,
        Component: RedirectPageUploadDocument,
    },
    {
        path: `${PATHS.auth}/${PATHS.expiredLink}`,
        Component: ExpiredLinkScreen,
    },
    {
        path: `${PATHS.contact}/${PATHS.verifyEmail}`,
        Component: VerifyEmailScreen,
    },
    {
        path: `${PATHS.sharedData.root}/${PATHS.sharedData.metrics}`,
        Component: SharedDataMetricsPage,
    },
    {
        path: `${PATHS.sharedData.root}/${PATHS.sharedData.all}`,
        Component: SharedDataAllPage,
    },
    {
        path: `${PATHS.sharedData.root}/${PATHS.sharedData.preview}`,
        Component: SharedDataPreviewPage,
    },
    {
        path: '/',
        Component: PageWrapper,
        children: [
            { path: `/`, Component: Dashboard },
            { path: `${PATHS.notifications}`, Component: NotificationsList },
            { path: `${PATHS.tasks}`, Component: AllTasks },
            {
                path: `${PATHS.myProfile}/*`,
                Component: UserPersonalInfo,
            },
            {
                path: `${PATHS.farmProfile}/*`,
                Component: FarmProfile,
            },
            {
                path: `${PATHS.disasterPlanning}/*`,
                Component: DisasterPlanningStepper,
            },
            {
                path: `${PATHS.otherServices}/*`,
                Component: OtherServices,
            },
            {
                path: `${PATHS.landpartners}/*`,
                Component: LandPartners,
                children: [
                    {
                        path: `${PATHS.profiles}`,
                        Component: LandPartnerProfilesPage,
                    },
                    {
                        path: `${PATHS.profiles}/${PATHS.create}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.growers}/${PATHS.create}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.profiles}/:id/${PATHS.update}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.growers}/:id/${PATHS.update}`,
                        Component: LandPartnerWizard,
                    },
                    {
                        path: `${PATHS.profiles}/:id/*`,
                        Component: LandPartnersDetailPage,
                    },
                    {
                        path: `${PATHS.growers}/:id/*`,
                        Component: LandPartnersDetailPage,
                    },
                    {
                        path: `${PATHS.allProfiles}/${PATHS.create}`,
                        Component: ContactWizard,
                    },
                    {
                        path: `${PATHS.allProfiles}/:id/${PATHS.update}`,
                        Component: ContactWizard,
                    },
                    {
                        path: `${PATHS.allProfiles}`,
                        Component: AllProfiles,
                    },
                    {
                        path: `${PATHS.growers}`,
                        Component: Growers,
                    },

                    {
                        path: `${PATHS.allProfiles}/:id/*`,
                        Component: AllProfilesDetailsPage,
                    },

                    {
                        path: `${PATHS.team}`,
                        Component: () => <div>team</div>,
                    },
                ],
            },
            {
                path: `${PATHS.landPortfolio}/*`,
                Component: LandPortfolio,
                children: [
                    {
                        path: `${PATHS.manage}`,
                        Component: ManageLeasesPage,
                    },
                    {
                        path: `${PATHS.fields}`,
                        Component: Fields,
                    },
                    {
                        path: `${PATHS.fields}/:id/*`,
                        Component: FieldsDetails,
                    },
                    {
                        path: `${PATHS.manage}/${PATHS.create}`,
                        Component: LeaseWizard,
                    },
                    {
                        path: `${PATHS.manage}/:id/${PATHS.update}`,
                        Component: LeaseWizard,
                    },

                    {
                        path: `${PATHS.manage}/:id/*`,
                        Component: LeaseDetailPage,
                    },
                    {
                        path: `${PATHS.locations}`,
                        Component: () => <div>locations</div>,
                    },
                    {
                        path: `${PATHS.reports}`,
                        Component: () => <div>reports</div>,
                    },
                ],
            },
            {
                path: `${PATHS.services}/*`,
                Component: Services,
                children: [
                    {
                        path: `${PATHS.disasterPlanning}`,
                        Component: DisasterPlanning,
                    },
                    {
                        path: `${PATHS.disasterPlanning}/${PATHS.create}`,
                        Component: DisasterPlanningStepper,
                    },
                    {
                        path: `${PATHS.allServices}`,
                        Component: () => <div>all services</div>,
                    },
                ],
            },
            {
                path: `${PATHS.payments}/*`,
                Component: Payments,
                children: [
                    {
                        path: `${PATHS.overview}`,
                        Component: PaymentOverview,
                    },
                    {
                        path: `${PATHS.calculate}`,
                        Component: () => <div>calculate</div>,
                    },
                    {
                        path: `${PATHS.configure}`,
                        Component: () => <div>configure</div>,
                    },
                ],
            },
            {
                path: `${PATHS.communications}/*`,
                Component: Communications,
                children: [
                    {
                        path: `${PATHS.calendar}`,
                        Component: () => <div>calendar</div>,
                    },
                    {
                        path: `${PATHS.taskAndReminders}`,
                        Component: () => <div>taskAndReminders</div>,
                    },
                    {
                        path: `${PATHS.newsletters}`,
                        Component: () => <div>newsletters</div>,
                    },
                    {
                        path: `${PATHS.surveys}`,
                        Component: () => <div>surveys</div>,
                    },
                ],
            },
            {
                path: `${PATHS.settings}/*`,
                Component: Settings,
                children: [
                    {
                        path: `${PATHS.orgAndUsers}/*`,
                        Component: OrganizationsAndUsers,
                    },
                    {
                        path: `${PATHS.viewsSettings}`,
                        Component: NotificationsAndViews,
                    },
                    {
                        path: `${PATHS.notificationsSettings}`,
                        Component: NotificationsSettings,
                    },
                ],
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}/*`,
                Component: Organizations,
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}/${PATHS.create}`,
                Component: OrganizationWizard,
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.organizations}/:id/${PATHS.update}`,
                Component: OrganizationWizard,
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.roles}/*`,
                Component: Roles,
            },
            {
                path: `${PATHS.settings}/${PATHS.orgAndUsers}/${PATHS.users}/*`,
                Component: Users,
            },
            {
                path: `${PATHS.generalManagerSettings.root}/*`,
                Component: GeneralManagerSettingsWrapper,
                children: [
                    {
                        path: `*`,
                        Component: GeneralManagerSettings,
                    },
                    {
                        path: `${PATHS.generalManagerSettings.landPartnerSharing}`,
                        Component: ManageLandPartnerSharing,
                    },
                    {
                        path: `${PATHS.archive}`,
                        Component: ArchiveObjects,
                    },
                    {
                        path: `${PATHS.integrations}`,
                        Component: Integrations,
                    },
                ],
            },
        ],
        errorElement: <StubDummyComponent title={'Something happened'} />,
    },
]);
