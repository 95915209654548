export const ENDPOINTS = {
    login: '/auth/login',
    validateToken: '/auth/validate-token',
    activate: '/auth/activate',
    reInvite: '/api/v1/users/re-invite',
    subscriptions: {
        root: '/api/v1/subscriptions',
    },
    growers: {
        root: '/api/v1/growers',
        summary: '/api/v1/growers/summary',
    },
    account: {
        root: '/api/v1/account',
        changePassword: '/api/v1/account/change-password',
        resetPassword: '/api/v1/account/reset-password',
        finishResetPassword: '/api/v1/account/reset-password/finish',
        layouts: '/api/v1/account/layouts',
    },
    users: {
        root: '/api/v1/users',
        search: '/api/v1/users/search',
        companyAdmin: '/api/v1/users/company-admin',
        documents: '/api/v1/landpartners/documents',
        documentsList: '/api/v1/landpartners/documents/v2',
        lastOutreach: '/api/v1/contacts/last-outreach',
        organizationUsers: '/api/v1/users/organization',
        photo: '/api/v1/profile-photos',
    },
    organizations: {
        root: '/api/v1/organizations',
        logo: '/api/v1/organizations/logo',
        summary: '/api/v1/organizations/summary',
        config: {
            root: '/api/v1/organizations/configurations',
            current: '/api/v1/organizations/configurations/current',
        },
    },
    roles: {
        root: '/api/v1/roles',
    },
    permissions: {
        root: '/api/v1/permissions',
    },
    landowners: {
        root: '/api/v1/landowners',
    },
    landpartners: {
        root: '/api/v1/landpartners',
        summary: '/api/v1/landpartners/summary',
        notificationsSummary: '/api/v1/notifications/summary',
        user: '/api/v1/landpartners/user',
        activity: '/api/v1/activities',
        history: '/api/v1/landpartners/history',
        download: '/api/v1/landpartners/download',
        documentByYear: '/api/v1/landpartners/documents/grouped-by-year',
        search: '/api/v1/landpartners/search',
        archive: {
            root: '/api/v1/landpartners/archive',
            restore: '/api/v1/landpartners/archive/restore',
        },
    },
    relationshipTree: {
        root: '/api/v1/relationship-tree',
        assignContact: '/api/v1/relationship-tree/assign-contact',
        removeContact: '/api/v1/relationship-tree/remove-contact',
        labels: '/api/v1/relationship-tree/labels',
    },
    contacts: {
        root: '/api/v1/contacts',
        settings: '/api/v1/contacts/settings',
        archive: '/api/v1/contacts/archive',
        restore: '/api/v1/contacts/archive/restore',
        download: '/api/v1/contacts/download',
        verifyPrimaryEmail: '/api/v1/contacts/verification/primary-email',
        verifySecondaryEmail: '/api/v1/contacts/verification/secondary-email',
        verifyEmail: '/api/v1/contacts/verification/verify-email',
        notes: {
            root: '/api/v1/contacts/notes',
            attachments: '/api/v1/contacts/notes/attachments',
            pin: '/api/v1/contacts/notes/pin',
            unpin: '/api/v1/contacts/notes/unpin',
            archive: '/api/v1/contacts/notes/archive',
            restore: '/api/v1/contacts/notes/restore',
        },
    },
    shareData: {
        root: '/api/v1/share-data/settings',
        contacts: '/api/v1/share-data/settings/contacts',
    },
    sharedData: {
        tokens: '/api/v1/shared-data/tokens',
        permanent: {
            grower: '/api/v1/shared-data/grower',
            dashboard: '/api/v1/shared-data/dashboard',
            leases: '/api/v1/shared-data/leases',
            payments: '/api/v1/shared-data/payments/scheduled',
            inviteGrower: '/api/v1/shared-data/invite-grower',
            documents: {
                root: '/api/v1/shared-data/documents',
            },
        },
        temporary: {
            grower: '/api/v1/shared-data/temporary/grower',
            dashboard: '/api/v1/shared-data/temporary/dashboard',
            leases: '/api/v1/shared-data/temporary/leases',
            payments: '/api/v1/shared-data/temporary/payments/scheduled',
            inviteGrower: '/api/v1/shared-data/temporary/invite-grower',
            documents: {
                root: '/api/v1/shared-data/temporary/documents',
                download: '/api/v1/shared-data/documents/download',
                downloadZip: '/api/v1/shared-data/documents/download/zip',
            },
        },
        preview: {
            grower: '/api/v1/shared-data/preview/grower',
            dashboard: '/api/v1/shared-data/preview/dashboard',
            leases: '/api/v1/shared-data/preview/leases',
            payments: '/api/v1/shared-data/payments/scheduled/preview',
            documents: '/api/v1/shared-data/preview/documents',
        },
    },
    leases: {
        root: '/api/v1/leases',
        history: '/api/v1/leases/history',
        drafts: '/api/v1/leases/drafts',
        search: '/api/v1/leases/search',
        notes: {
            root: '/api/v1/leases/notes',
            pin: '/api/v1/leases/notes/pin',
            unpin: '/api/v1/leases/notes/unpin',
            archive: '/api/v1/leases/notes/archive',
            restore: '/api/v1/leases/notes/restore',
        },
    },
    bugReports: {
        root: '/api/v1/reports',
    },
    leaseDocuments: {
        root: '/api/v1/documents',
        documentByYear: '/api/v1/documents/grouped-by-lease',
    },
    payments: {
        schedulesSummary: '/api/v1/payment-schedules/summary',
        schedulesStatus: '/api/v1/payment-schedules/status',
        schedules: '/api/v1/payment-schedules',
        upload: '/api/v1/payment-schedules/upload',
        scheduled: '/api/v1/payment-schedules/scheduled',
        paid: '/api/v1/payment-schedules/paid',
        stats: '/api/v1/payment-schedules/stats',
    },
    qbo: {
        connectToQuickBooks: '/api/v1/qbo/connectToQBO',
    },
    notifications: {
        root: '/api/v1/notifications',
        settings: '/api/v1/notifications/settings',
        info: '/api/v1/notifications/info',
        snooze: '/api/v1/notifications/snooze',
        read: '/api/v1/notifications/read',
    },
    integrations: {
        root: '/api/v1/integrations',
        quickBooks: {
            connect: '/api/v1/qbo/connectToQBO',
        },
    },
    documents: {
        root: '/api/v1/documents',
        shared: '/api/v1/shared/documents',
        revoke: '/api/v1/shared/documents/revoke',
        request: '/api/v1/shared/documents/requests',
        upload: '/api/v1/documents/upload',
        download: {
            root: '/api/v1/documents/download',
            zip: '/api/v1/documents/download/zip',
        },
    },
    activity: {
        root: '/api/v1/activities',
        landpartner: '/api/v1/activities/land-partner',
    },
    customizeConfig: {
        customizeView: '/api/v1/customized-views',
    },
    search: {
        root: '/api/v1/search',
    },
    tasks: {
        root: '/api/v1/tasks',
        document: '/api/v1/tasks/documents',
        history: '/api/v1/tasks/history',
        summary: '/api/v1/tasks/summary',
        done: '/api/v1/tasks/done',
        assigned: '/api/v1/tasks/assigned',
    },
    calendar: {
        root: '/api/v1/calendar',
    },
};

export const FREE_ACCESS_APIS = [
    ENDPOINTS.login,
    ENDPOINTS.activate,
    ENDPOINTS.validateToken,
    ENDPOINTS.account.resetPassword,
    ENDPOINTS.account.finishResetPassword,
];
