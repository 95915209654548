import React, { useState } from 'react';
import { Box } from '@mui/material';
import TwoColumnLayout from '../../layouts/TwoColumnLayout';
import ExpandableGroupStepper from '../../design/Stepper/ExpandableGroupStepper';
import DisasterPlanningForm from './DisasterPlanningForm';
import theme from '../../styles/theme';
import { useLocation } from 'react-router-dom';

export default function DisasterPlanningStepper() {
    const { state } = useLocation();

    const [pageNumber, setPageNumber] = useState(state?.stepNumber || 0);

    return (
        <Box m={1} bgcolor={theme.palette.backgroundB.backgroundB1}>
            <TwoColumnLayout
                header={'Disaster planning'}
                type={'third'}
                leftComponent={
                    <Box p={4}>
                        <ExpandableGroupStepper
                            stepNumber={pageNumber}
                            stepGroups={stepGroups}
                        />
                    </Box>
                }
                rightComponent={
                    <DisasterPlanningForm
                        totalSteps={12}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                }
            />
        </Box>
    );
}
const stepGroups = [
    {
        groupLabel: 'Preparation and Response',
        steps: [
            'a. Emergency Contacts',
            'c. Critical Resources and Processes',
            'd. Evacuation + Safety',
            'e. Communication',
        ],
    },
    {
        groupLabel: 'Resource and Risk Management',
        steps: [
            'a. Crop Protection',
            'b. Resource Managements',
            'c. Recovery Procedures',
            'd. Environmental Concerns',
        ],
    },
    {
        groupLabel: 'Legal, Financial and Environmental',
        steps: [
            'a. Insurance and Financial Preparedness',
            'b. External Assistance',
            'c. Training and Preparedness',
            'd. Continuity of Operations',
        ],
    },
];
